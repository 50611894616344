import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { findHightestLevel, fixSpecialChar } from "utils/helpers";
import { Table, Row, Col, Pagination, message } from "antd";
import { useFormik } from "formik";
import {
  CheckOutlined,
  DeleteFilled,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import * as masterData from "../../utils/commonMasterData";
import { useDispatch, useSelector } from "react-redux";
import {
  setCMSData,
  setCurrentTabState,
  setCmsId,
} from "redux/slice/commonSlice";
import { useToasts } from "react-toast-notifications";
import useAxios from "utils/axios";
import { serviceUrl } from "services/serviceUrl";

function CMSUmpires({
  styles,
  setStep,
  setInfo,
  info,
  setActiveKey,

  status,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const authLevel = localStorage.getItem("csa_level");
  const memberName = localStorage.getItem("csa_member_name");
  const cmsData = useSelector((state) => state.auth.cms_data);
  const { id, type, editData } = location?.state || {};
  const streamingLogos = cmsData?.streamingLogos;

  console.log(cmsData, "iii");

  const cms_id = useSelector((state) => state.auth.cms_id);
  const hasId = id ? id : cms_id;
  const isEdit = type === "EDIT";
  const { addToast } = useToasts();
  const tabData = cmsData;
  const steps = ["Scorers", "Umpires"];

  const [tableData2, settableData2] = useState(
    cmsData?.tab3Step2?.tableData2 || cmsData?.tab3Step2 || []
  );
  const handleAdd = (arrField) => {
    const isThere = tableData2?.find((item) => item?.key === arrField?.key);
    if (!isThere) {
      settableData2([...tableData2, arrField]);
    } else {
      addToast("Same Umpire added Already", {
        appearance: "warning",
      });
    }
  };
  const handleRemove = (fieldArr) => {
    const arr = tableData2?.filter((item) => item?.key !== fieldArr);
    settableData2(arr);
  };
  const getColumns = (type = 1) => [
    {
      title: "First Name",
      dataIndex: "fName",
      key: "fName",
    },
    {
      title: "Last Name",
      dataIndex: "lName",
      key: "lName",
    },
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "Premier League",
      dataIndex: "premierLeague",
      key: "premierLeague",
    },
    {
      title: "ICC Panel",
      dataIndex: "iccPanel",
      key: "iccPanel",
    },
    {
      title: "Action",
      key: "action",
      render: (item) =>
        type === 1 ? (
          filteredTableData2.some((i) => i.key === item?.key) ? (
            <CheckOutlined style={{ cursor: "pointer", color: "#007237" }} />
          ) : (
            <PlusOutlined
              style={{ cursor: "pointer", color: "#007237" }}
              onClick={() => handleAdd(item)}
            />
          )
        ) : (
          <DeleteOutlined
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => handleRemove(item?.id)}
          />
        ),
      //align: "center",
    },
  ];

  const data = info?.step1 || {};
  const dispatch = useDispatch();
  const cms_data = useSelector((state) => state.auth.cms_data);

  const { callAPI, response, error, loading } = useAxios({
    url: serviceUrl.setCMS,
    method: "post",
  });
  const {
    callAPI: SearchAPI,
    response: searchResponse,
    error: searchErr,
    loading: searchLoad,
  } = useAxios({
    url: serviceUrl.getCMSUmpireList,
    method: "get",
    query: true,
  });

  useEffect(() => {
    SearchAPI();
  }, []);

  const handleSearch = (val) => {
    // if (!val?.membersName) {
    //  addToast("Please select member name", {
    //   appearance: "warning",
    //  });
    //  return;
    // }
    if (!val?.membersName && !val?.firstName && !val?.lastName) {
      SearchAPI();
    }
    //   SearchAPI(
    //    `?keyword=${val?.firstName} ${val.lastName}&member_id=${val?.membersName}`
    //   );
    // if(val?.membersName && !val?.firstName && !val?.lastName){
    //  SearchAPI(
    //    `?uid=&member_id=${val?.membersName}`, true
    //  );
    //  }
    else {
      SearchAPI(
        `?keyword=${val?.firstName ? val?.firstName : ""} ${
          val?.lastName ? val.lastName : ""
        }&member_id=${val?.membersName}`,
        true
      );
    }
  };
  const formik = useFormik({
    initialValues: {
      membersName: data ? data?.membersName : "",
      level: data ? data?.level : "",
      firstName: data ? fixSpecialChar(data?.firstName) : "",
      lastName: data ? fixSpecialChar(data?.lastName) : "",
    },
    enableReinitialize: true,
    onSubmit: (values) => handleSearch(values),
  });

  const tableDataRes = searchResponse?.rows?.map((item) => ({
    ...item,
    key: item?.id,
    field_first_name: fixSpecialChar(item?.field_first_name),
    field_last_name: fixSpecialChar(item?.field_last_name),
    firstname: fixSpecialChar(item?.field_first_name),
    lastname: fixSpecialChar(item?.field_last_name),
    level: findHightestLevel(
      item?.field_level_1,
      item?.field_level_2,
      item?.field_level_3
    ),
    premierLeague: item?.field_premier_league,
    member:
      cms_data?.tab2?.step1?.membersNameP?.find((x) => x?.id == item?.member_id)
        ?.name || item?.member_name,
    iccPanel: item?.field_icc_panel,
  }));
  const tabData1 = tabData?.tab1;
  const csaLevel = tabData1?.competition_settings?.find(
    (x) => x?.key === "csa-levels"
  )?.value;

  console.log(tabData1, "info3");
  const creator = authLevel !== "csa" && memberName ? memberName : "csa";
  const onSubmitClick = () => {
    setInfo({
      step1: info?.step1,
      step2: tableData2,
    });

    const obj = {
      name: tabData1?.name,
      start_date: tabData1?.start_date,
      end_date: tabData1?.end_date,
      created_by: creator,
      competition_settings: [
        {
          key: "competition_type",
          value: tabData1?.competition_settings?.find(
            (elem) => elem?.key === "competition_type"
          )?.value,
        },
        authLevel === "csa"
          ? {
              key: "csa-levels",
              value: tabData1?.competition_settings?.find(
                (elem) => elem?.key === "csa-levels"
              )?.value,
            }
          : {
              key: "member-levels",
              value: tabData1?.competition_settings?.find(
                (elem) => elem?.key === "member-levels"
              )?.value,
            },
        csaLevel === "club"
          ? {
              key: "competition_league_club",
              value: tabData1?.competition_settings?.find(
                (elem) => elem?.key === "competition_league_club"
              )?.value,
            }
          : csaLevel === "primary-school" || csaLevel === "high-school"
          ? {
              key: "competition_league_school",
              value: tabData1?.competition_settings?.find(
                (elem) => elem?.key === "competition_league_school"
              )?.value,
            }
          : {
              key: "competition_league_other",
              value: tabData1?.competition_settings?.find(
                (elem) => elem?.key === "competition_league_other"
              )?.value,
            },
        {
          key: "season_number",
          value: tabData1?.competition_settings?.find(
            (elem) => elem?.key === "season_number"
          )?.value,
        },
        {
          key: "age_group",
          value: tabData1?.competition_settings?.find(
            (elem) => elem?.key === "age-group"
          )?.value,
        },
        {
          key: "gender",
          value: tabData1?.competition_settings?.find(
            (elem) => elem?.key === "gender"
          )?.value,
        },
        {
          key: "analysis_options",
          values:
            tabData1?.competition_settings?.find(
              (elem) => elem?.key === "analysis_options"
            )?.values || "",
        },

        {
          key: "finals",
          values: tabData1?.competition_settings?.find(
            (elem) => elem?.key === "finals"
          )?.values,
        },
        tabData1?.competition_settings
          ?.find((elem) => elem?.key === "finals")
          ?.values.find((item) => item === "finals")
          ? {
              key: "reserve_day",
              values: tabData1?.competition_settings?.find(
                (elem) => elem?.key === "reserve_day"
              )?.values,
            }
          : {
              key: "reserve_day",
              values: [],
            },
        {
          key: "scoring_type",
          values: tabData1?.competition_settings?.find(
            (elem) => elem?.key === "scoring_type"
          )?.values,
        },
        {
          key: "match_format",
          value: tabData1?.competition_settings?.find(
            (elem) => elem?.key === "match_format"
          )?.value,
        },
        {
          key: "number_of_pools",
          value: tabData1?.competition_settings?.find(
            (elem) => elem?.key === "number_of_pools"
          )?.value,
        },
        {
          key: "number_of_teams_per_Pool",
          value: tabData1?.competition_settings?.find(
            (elem) => elem?.key === "number_of_teams_per_Pool"
          )?.value,
        },
        {
          key: "number_of_matches_vs_each_team_in_pool",
          value: tabData1?.competition_settings?.find(
            (elem) => elem?.key === "number_of_matches_vs_each_team_in_pool"
          )?.value,
        },
        {
          key: "num_overs_per_innings",
          value: tabData1?.competition_settings?.find(
            (elem) => elem?.key === "overs_per_innings"
          )?.value,
        },
        {
          key: "innings_per_team",
          value: tabData1?.competition_settings?.find(
            (elem) => elem?.key === "innings_per_team"
          )?.value
            ? tabData1?.competition_settings?.find(
                (elem) => elem?.key === "innings_per_team"
              )?.value
            : "",
        },
        {
          key: "ball_colour",
          value: tabData1?.competition_settings?.find(
            (elem) => elem?.key === "ball_colour"
          )?.value,
        },
        {
          key: "differently_abled",
          value: tabData1?.competition_settings?.find(
            (elem) => elem?.key === "differently_abled"
          )?.value,
        },
        {
          key: "sponsor_logo",
          value:
            tabData1?.competition_settings?.find(
              (elem) => elem?.key === "sponsor_logo"
            )?.value ?? "",
        },
        {
          key: "competition_logo",
          value:
            tabData1?.competition_settings?.find(
              (elem) => elem?.key === "competition_logo"
            ).value !== null
              ? tabData1?.competition_settings?.find(
                  (elem) => elem?.key === "competition_logo"
                )?.value
              : "",
        },
        {
          key: "top_left_logo",
          value: streamingLogos?.ltLogo || "",
        },
        {
          key: "top_right_logo",
          value: streamingLogos?.rtLogo || "",
        },
        {
          key: "sponsor_logo_1",
          value: streamingLogos?.spLogo1 || "",
        },
        {
          key: "sponsor_logo_2",
          value: streamingLogos?.spLogo2 || "",
        },
        {
          key: "sponsor_logo_3",
          value: streamingLogos?.spLogo3 || "",
        },
        {
          key: "sponsor_logo_4",
          value: streamingLogos?.spLogo4 || "",
        },
        {
          key: "sponsor_logo_5",
          value: streamingLogos?.spLogo5 || "",
        },
        {
          key: "cms_participating_member_List",
          values: cms_data?.partMembers?.map(String),
          data: reqArr,
        },
        {
          key: "cms_host_member_list",
          values: cms_data?.hostMembers?.map(String),
          data: reqArrH,
        },
        {
          key: "team_list_CMS",
          values: cmsData?.tableData?.map((item) => item?.key).map(String),
          data: cmsData?.tableData,
        },
        {
          key: "venue_list",
          values: cmsData?.tableFieldData?.map((item) => item?.key).map(String),
          data: cmsData?.tableFieldData,
        },
        {
          key: "umpire_cms_list",
          values: tableData2?.map((item) => item.key).map(String),
          data: tableData2,
        },
        {
          key: "scorer_list_cms",
          values: info?.scorer?.map((item) => item.key).map(String),
          data: info?.scorer,
        },
      ],
    };
    dispatch(
      setCMSData({
        ...cms_data,
        tab1Step1: cms_data?.tab1Step1,
        tab1Step2: cms_data?.tab1Step2,
        tab1: cms_data?.tab1,
        tab2Step1: cms_data?.tab2Step1,
        tab2Step2: cms_data?.tab2Step2,
        tab2Step3: cms_data?.tab2Step3,
        tab2: cms_data?.tab2,
        tab3Step1: cms_data?.tab3Step1,
        tab3Step2: tableData2,
        tab3: {
          competition_settings: [
            {
              key: "umpire_cms_list",
              values: tableData2,
            },
            {
              key: "scorer_list_cms",
              values: info?.scorer,
            },
          ],
          step1: info?.scorer,
          step2: tableData2,
        },
      })
    );
    callAPI(hasId ? { ...obj, ...{ id: hasId } } : obj);
  };

  const reqArr = cms_data?.memberRes?.filter((item) =>
    cms_data?.partMembers?.includes(item?.id)
  );
  const reqArrH = cms_data?.memberRes?.filter((item) =>
    cms_data?.hostMembers?.includes(item?.id)
  );
  useEffect(() => {
    if (response?.data?.id) {
      addToast(isEdit ? "Updated Successfully" : "Successfully Added ", {
        appearance: "success",
      });
      setActiveKey("5");
      dispatch(setCmsId(response?.data?.id));
      dispatch(setCurrentTabState(5));
      setStep(1);
      resetForm();
    } else if (error) {
      addToast(error?.message || "Something went wrong", {
        appearance: "error",
      });
    }
  }, [response, error]);
  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;
  const tableData = values?.level
    ? tableDataRes?.filter((x) => x?.level == values?.level)
    : tableDataRes;

  const onChange = (page) => {
    SearchAPI(
      `?page=${page - 1}&per_page=10&member_id=${values?.membersName || "All"}`,
      true
    );
  };

  const filteredTableData = tableData?.map((x) => ({
    fName: x?.firstname?.replace(/&#039;/g, "'"),
    lName: x?.lastname?.replace(/&#039;/g, "'"),
    ...x,
  }));

  const filteredTableData2 = tableData2?.map((x) => ({
    fName: x?.firstname?.replace(/&#039;/g, "'"),
    lName: x?.lastname?.replace(/&#039;/g, "'"),
    ...x,
  }));

  return (
    <div className="mt-3 pt-3 px-3">
      <CsaStepper steps={steps} activeKey={1} />
      {/* {fetching ? (
    <Spin />
   ) : ( */}
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        className="mt-3 pt-3"
        align="Start"
      >
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Member Name"
            placeholder="Select Member"
            name="membersName"
            value={values.membersName}
            menuArr={reqArr?.map((item) => ({
              label: item?.name,
              value: item?.id,
            }))}
            optionType={true}
            onChange={(e) => {
              setFieldValue("membersName", e);
            }}
            onBlur={() => setFieldTouched("membersName", true)}
            touched={touched.membersName}
            error={errors.membersName || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaDropDown
            label="Level"
            placeholder="Select Level"
            name="level"
            value={values.level}
            menuArr={masterData.levelList}
            optionType
            onChange={(e) => {
              setFieldValue("level", e);
            }}
            defaultValue={values.level}
            onBlur={handleBlur}
            touched={touched.level}
            error={errors.level || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaTextInput
            label="First Name"
            placeholder="Enter First Name"
            type="text"
            name="firstName"
            onChange={handleChange}
            value={values.firstName}
            onBlur={handleBlur}
            touched={touched.firstName}
            error={errors.firstName || null}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <CsaTextInput
            label="Last Name"
            placeholder="Enter Last Name"
            type="text"
            name="lastName"
            onChange={handleChange}
            value={values.lastName}
            onBlur={handleBlur}
            touched={touched.lastName}
            error={errors.lastName || null}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="d-flex justify-content-end"
        >
          <CsaButton
            label="Search"
            textColor="#FFF"
            onClick={() => handleSubmit()}
            btnColor="#007237"
            loading={searchLoad}
            //   disabled={checkValidity()}
          />
        </Col>
      </Row>
      <div className="mt-2 pt-2">
        <p className={styles.name}>Search Results:</p>
        <Table
          className="center-aligned"
          loading={searchLoad}
          columns={getColumns()}
          dataSource={filteredTableData}
          pagination={false}
          scroll={{
            x: 700,
          }}
          rowClassName={(rec) =>
            filteredTableData2.some((item) => item.key === rec?.key) &&
            "disabled-row"
          }
        />
      </div>
      <div className=" d-flex w-100 justify-content-end mt-5">
        <Pagination
          onChange={onChange}
          current={searchResponse?.pager?.current_page + 1}
          total={searchResponse?.pager?.total_items}
          size={"small"}
        />
      </div>
      <div className="mt-2 pt-2">
        <p className={styles.name}>Selected Umpires:</p>
        <Table
          className="center-aligned"
          //   loading={loading}
          columns={getColumns(2)}
          dataSource={filteredTableData2}
          pagination={false}
          scroll={{
            x: 700,
          }}
        />
        {/* <div className=" d-flex w-100 justify-content-end mt-5">
     <Pagination
       onChange={onChange}
       //    total={response?.pager?.total_items}
       size={"small"}
      />
    </div> */}
      </div>
      <div className="mt-4  d-flex w-100 justify-content-end">
        <CsaButton
          label="Back"
          textColor="#007237"
          onClick={() => {
            setInfo({ step1: info?.step1, step2: values });
            setStep(1);
          }}
          btnColor="#FFCB03"
        />
        <CsaButton
          label="Submit"
          textColor="#FFF"
          onClick={() => onSubmitClick()}
          btnColor="#007237"
          loading={loading}
          disabled={tableData2?.length === 0}
        />
      </div>

      {/* )} */}
    </div>
  );
}

export default CMSUmpires;
