/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Space, Spin } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
//Components
import CsaStepper from "Common/CsaStepper";
import CsaTextInput from "Common/CsaTextInput";
import CsaDropDown from "Common/CsaDropDown";
import CsaButton from "Common/CsaButton";
import CsaRangePicker from "Common/CsaRangePicker";
import CsaImageUpload from "Common/CsaImageUpload";
import CMSView from "../../Common/CompetitionInformation&Format/CMSView";
import useAxios from "utils/axios";
import axios from "axios";
import { serviceUrl } from "services/serviceUrl";
import { imgUrlToFile } from "utils/helpers";
import { getAllByDisplayValue } from "@testing-library/react";
import { useDispatch, useSelector } from "react-redux";
import { setCMSData } from "redux/slice/commonSlice";
import S3 from "react-aws-s3";

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS,
  secretAccessKey: process.env.REACT_APP_SECRET,
};

const CMSSchema = Yup.object().shape({
  competitionName: Yup.string().required("Enter Competition Name"),
  competitionType: Yup.string().required("Select Competition Type"),
  seasonNumber: Yup.string().required("Select season number"),
  league: Yup.string().required("Select league"),
  ageGroup: Yup.string().required("Select age group"),
  level: Yup.string().required("Select Level"),
  gender: Yup.string().required("Select Gender"),
  differentlyAbled: Yup.string().required("Select differently abled"),
  //startDate: Yup.string().required("Select Start Date"),
  endDate: Yup.string().required("Select End Date"),
});

function CompetitionInformation({
  styles,
  setStep,
  setInfo,
  info,
  isLocal,
  setLocal,
  img,
  setImg,
  img1,
  setImg1,
}) {
  const authLevel = localStorage.getItem("csa_level");
  const CMSData = useSelector((state) => state.auth.cms_data) || {};
  const location = useLocation();
  const navigate = useNavigate();
  const [defaults, setDefault] = useState(null);
  const steps = ["Competition Information", "Format"];
  const { id, type, editData } = location?.state || {};
  const tabData = CMSData?.tab1Step1 || {};

  const [fileId, setFileId] = useState(tabData?.competitionLogo || null);
  const [fileId2, setFileId2] = useState(tabData?.sponsorLogo || null);
  const isEdit = type === "EDIT";
  const isView = type === "VIEW";

  console.log(info?.fileCMSL, "info1");

  const {
    callAPI: fetchMasterDetail,
    response: fetchCMSMasterR,
    error: fetchCMSMasterErr,
    loading: fetchCMSMaster,
  } = useAxios({
    url: serviceUrl.getCMSMasterList,
    method: "get",
  });

  useEffect(() => {
    fetchMasterDetail();
  }, []);
  useEffect(() => {
    if (fetchCMSMasterR) {
      setDefault(fetchCMSMasterR?.data);
    }
  }, [fetchCMSMasterR]);

  const handleClear = () => {
    resetForm();
    navigate(-1);
  };

  // Redux Data
  // const tabData = CMSData
  //   ? CMSData?.find((item) => Object.keys(item)?.[0] === "tab1")?.tab1
  //   : null;

  const reqData = tabData ? tabData : editData;

  useEffect(() => {
    if (tabData && tabData?.sponsorLogo && img?.length === 0 && isEdit) {
      imgUrlToFile(tabData?.sponsorLogo, tabData?.competitionLogo, setImg1);
      setFileId2(tabData?.sponsorLogo);
    }
  }, [tabData?.sponsorLogo]);
  useEffect(() => {
    if (tabData && tabData?.competitionLogo && img1?.length === 0 && isEdit) {
      imgUrlToFile(tabData?.competitionLogo, tabData?.competitionLogo, setImg);
      console.log("YES");
      setFileId(tabData?.competitionLogo);
    }
  }, [tabData?.competitionLogo]);

  const data = tabData;
  console.log(fileId, fileId2, tabData, "dsds");
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      competitionName: data ? data?.competitionName : "",
      competitionType: data ? data?.competitionType : "",
      level: data ? data?.level : "",
      seasonNumber: data ? data?.seasonNumber : "",
      league: data ? data?.league : "",
      ageGroup: data ? data?.ageGroup : "",
      gender: data ? data?.gender : "",
      differentlyAbled: data ? data?.differentlyAbled || "None" : "None",
      startDate: data ? data?.startDate : "",
      endDate: data ? data?.endDate : "",
    },
    validationSchema: CMSSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setInfo({
        step1: values,
        step2: info?.step2,
        fileCMSL: fileId,
        fileSPONL: fileId2,
        defaultSettings: {
          matchFormat,
          ballColour,
          scoringType,
          analysisOpt,
          reserveDays,
          finals,
        },
      });
      dispatch(
        setCMSData({
          ...CMSData,
          tab1Step1: {
            ...values,
            competitionLogo: fileId,
            sponsorLogo: fileId2,
          },
        })
      );
      setStep(2);
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;
  const getKeyValue = (arr) =>
    arr?.map((item) => ({ label: item.name, value: item.value }));
  const {
    ageGroup = defaults?.find((elem) => elem?.key_name === "age-group")
      ?.configuration_values,
    seasonNumber = defaults?.find((elem) => elem?.key_name === "season_number")
      ?.configuration_values,
    matchFormat = defaults?.find((elem) => elem?.key_name === "match_format")
      ?.configuration_values,
    scoringType = defaults?.find((elem) => elem?.key_name === "scoring_type")
      ?.configuration_values,
    ballColour = defaults?.find((elem) => elem?.key_name === "ball_colour")
      ?.configuration_values,
    disabilityCategory = defaults?.find(
      (elem) => elem?.key_name === "differently_abled"
    )?.configuration_values,
    gender = defaults?.find((elem) => elem?.key_name === "gender")
      ?.configuration_values,
    levelCSA = defaults?.find((elem) => elem?.key_name === "csa-levels")
      ?.configuration_values,
    levelMember = defaults?.find((elem) => elem?.key_name === "member-levels")
      ?.configuration_values,
    competitionType = defaults?.find(
      (elem) => elem?.key_name === "competition_type"
    )?.configuration_values,
    competitionLeagueClub = defaults?.find(
      (elem) => elem?.key_name === "competition_league_club"
    )?.configuration_values,
    competitionLeagueSchool = defaults?.find(
      (elem) => elem?.key_name === "competition_league_school"
    )?.configuration_values,
    competitionLeagueOther = defaults?.find(
      (elem) => elem?.key_name === "competition_league_other"
    )?.configuration_values,
    analysisOpt = defaults?.find(
      (elem) => elem?.key_name === "analysis_options"
    )?.configuration_values,
    reserveDays = defaults?.find((elem) => elem?.key_name === "reserve_day")
      ?.configuration_values,
    finals = defaults?.find((elem) => elem?.key_name === "finals")
      ?.configuration_values,
  } = defaults || [];

  const checkValidity = () => {
    const {
      competitionName,
      competitionType,
      seasonNumber,
      league,
      ageGroup,
      level,
      gender,
      differentlyAbled,
      startDate,
      endDate,
    } = values;
    const isVAlid =
      competitionName &&
      competitionType &&
      seasonNumber &&
      league &&
      ageGroup &&
      level &&
      gender &&
      differentlyAbled &&
      startDate &&
      endDate &&
      Object.keys(errors).length === 0;
    return isVAlid ? false : true;
  };

  const time = new Date() + new Date();
  var ts = Math.round(new Date().getTime() / 1000);

  //  const uploadImage = async (options) => {
  //   const { onSuccess, onError, file } = options;
  //   // const file = data1?.file;
  //   const reader = new FileReader();
  //   reader.onload = function (e) {
  //    const blob = new Blob([new Uint8Array(e.target.result)], {
  //     type: file.type,
  //    });
  //  // const fileName = file.name;

  //   const fileType = file.type
  //   const fileName = ts+file.type.replace('image/','.')
  //
  //    const headers = {
  //     "Content-Type": "application/octet-stream",
  //     Authorization: "Bearer " + localStorage.getItem("csa_token"),
  //     "Content-Disposition": `file;filename="${fileName}"`,
  //    };
  //    axios
  //     .post(serviceUrl.imgUpload, blob, {
  //      headers: headers,
  //     })
  //     .then((res) => {
  //      setFileId(res?.data?.uri?.[0]?.url);
  //      onSuccess(file);
  //     })
  //     .catch((err) => {
  //      onError({ event: err });
  //     });
  //   };
  //   reader.readAsArrayBuffer(file);
  //   return true;
  //  };

  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;
    const reader = new FileReader();
    reader.onload = function (e) {
      const files = new Blob([new Uint8Array(e.target.result)], {
        type: file.type,
      });

      const fileName = ts + file.type.replace("image/", ".");
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(files, fileName)
        .then((data) => setFileId(data.location))
        .then(onSuccess(files))
        .catch((err) => console.error(err));
    };

    reader.readAsArrayBuffer(file);
    return true;
  };

  const props = {
    name: "file",
    fileList: img,
    customRequest: uploadImage,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      setImg(info?.fileList);
    },
    onRemove() {
      setFileId(null);
    },
    // removeLogo () {
    //   setFileId(null)
    //  }
  };

  const uploadImage2 = async (options) => {
    const { onSuccess, onError, file } = options;
    const reader = new FileReader();
    reader.onload = function (e) {
      const files = new Blob([new Uint8Array(e.target.result)], {
        type: file.type,
      });
      const fileName = ts + file.type.replace("image/", ".");
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(files, fileName)
        .then((data) => setFileId2(data.location))
        .then(onSuccess(data.location))
        .catch((err) => console.error(err));
    };

    reader.readAsArrayBuffer(file);
    return true;
  };

  //  const uploadImage2 = async (options) => {
  //   const { onSuccess, onError, file } = options;
  //   // const file = data1?.file;
  //   const reader = new FileReader();
  //   reader.onload = function (e) {
  //    const blob = new Blob([new Uint8Array(e.target.result)], {
  //     type: file.type,
  //    });
  //    const fileName = ts+file.type.replace('image/','.')
  //
  //    const headers = {
  //     "Content-Type": "application/octet-stream",
  //     Authorization: "Bearer " + localStorage.getItem("csa_token"),
  //     "Content-Disposition": `file;filename="${fileName}"`,
  //    };
  //    axios
  //     .post(serviceUrl.imgUpload, blob, {
  //      headers: headers,
  //     })
  //     .then((res) => {
  //      setFileId2(res?.data?.uri?.[0]?.url);
  //      onSuccess(file);
  //     })
  //     .catch((err) => {
  //      onError({ event: err });
  //     });
  //   };
  //   reader.readAsArrayBuffer(file);
  //   return true;
  //  };
  //

  const props2 = {
    name: "file",
    fileList: img1,
    customRequest: uploadImage2,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      setImg1(info?.fileList);
    },
    onRemove() {
      setFileId2(null);
    },
  };

  return (
    <>
      <div className="mt-3 pt-3 px-3">
        <CsaStepper steps={steps} activeKey={0} />
        {fetchCMSMaster ? (
          <Spin />
        ) : (
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            className="mt-3 pt-3 "
            align="Start"
          >
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ width: "20px" }}
              className={styles.imageUpload}
            >
              {/* <ImgUpload2 /> */}
              <CsaImageUpload {...props} type="landscape" />
              {/* <button  >Remove</button> */}
              <p className={styles.name}>{"Competition Logo"}</p>
              <p style={{ fontSize: "12px" }}>
                (Recommended resolution 1200*800px or 3:2 Aspect Ratio)
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className={styles.imageUpload}
            >
              <CsaImageUpload {...props2} type="banner" />
              <p className={styles.name}>{"Sponsor Banner"}</p>
              <p style={{ fontSize: "12px" }}>
                (Recommended resolution 1200*200px or 6:1 Aspect Ratio)
              </p>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Competition Type"
                placeholder="Select Competition Type"
                required
                name="competitionType"
                value={values.competitionType}
                menuArr={getKeyValue(competitionType)}
                optionType
                onChange={(e) => {
                  setFieldValue("competitionType", e);
                }}
                onBlur={() => setFieldTouched("competitionType", true)}
                touched={touched.competitionType}
                error={errors.competitionType || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaTextInput
                label="Competition Name"
                placeholder="Enter Competition Name"
                type="text"
                name="competitionName"
                onChange={handleChange}
                value={values.competitionName}
                required={isLocal}
                onBlur={handleBlur}
                touched={touched.competitionName}
                error={errors.competitionName || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Level"
                placeholder="Select Level"
                required
                name="level"
                value={values.level}
                menuArr={
                  authLevel === "member"
                    ? getKeyValue(levelMember)
                    : getKeyValue(levelCSA)
                }
                optionType
                onChange={(e) => {
                  setFieldValue("level", e);
                }}
                onBlur={() => setFieldTouched("level", true)}
                touched={touched.level}
                error={errors.level || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Season Number"
                placeholder="Select Season Number"
                name="seasonNumber"
                value={values.seasonNumber}
                menuArr={getKeyValue(seasonNumber)?.sort((a, b) => {
                  const [a1, a2] = a.label.split("/");
                  const [b1, b2] = b.label.split("/");
                  return (b1 - a1) * 100 + (b2 - a2);
                })}
                optionType
                onChange={(e) => {
                  setFieldValue("seasonNumber", e);
                }}
                required
                defaultValue={values.seasonNumber}
                onBlur={handleBlur}
                touched={touched.seasonNumber}
                error={errors.seasonNumber || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="League"
                placeholder="Select League"
                name="league"
                value={values.league}
                menuArr={
                  values.level === "club"
                    ? getKeyValue(competitionLeagueClub)
                    : values.level === "primary-school" ||
                      values.level === "high-school"
                    ? getKeyValue(competitionLeagueSchool)
                    : getKeyValue(competitionLeagueOther)
                }
                optionType
                onChange={(e) => {
                  setFieldValue("league", e);
                }}
                required
                defaultValue={values.league}
                onBlur={handleBlur}
                touched={touched.league}
                error={errors.league || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Gender"
                placeholder="Select Gender"
                required
                name="gender"
                value={values.gender}
                menuArr={getKeyValue(gender)}
                optionType
                onChange={(e) => {
                  setFieldValue("gender", e);
                }}
                onBlur={() => setFieldTouched("gender", true)}
                touched={touched.gender}
                error={errors.gender || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Age Group"
                placeholder="Select Age Group"
                required
                name="ageGroup"
                value={values.ageGroup}
                menuArr={getKeyValue(ageGroup)}
                optionType
                onChange={(e) => {
                  setFieldValue("ageGroup", e);
                }}
                onBlur={() => setFieldTouched("ageGroup", true)}
                touched={touched.ageGroup}
                error={errors.ageGroup || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaDropDown
                label="Differently Abled"
                placeholder="Select Differently Abled"
                required
                name="differentlyAbled"
                value={values.differentlyAbled}
                menuArr={getKeyValue(disabilityCategory)}
                optionType
                onChange={(e) => {
                  setFieldValue("differentlyAbled", e);
                }}
                defaultValue={values.differentlyAbled}
                onBlur={() => setFieldTouched("differentlyAbled", true)}
                touched={touched.differentlyAbled}
                error={errors.differentlyAbled || null}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CsaRangePicker
                label="Competition Duration"
                name="startDate"
                allowClear={true}
                required
                //  disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                onChange={(e) => {
                  console.log(e, "datee");
                  if (e) {
                    setFieldValue(
                      "startDate",
                      moment(e?.[0])?.format("YYYY-MM-DD")
                    );
                    setFieldValue(
                      "endDate",
                      moment(e?.[1])?.format("YYYY-MM-DD")
                    );
                  } else {
                    setFieldValue("startDate", "");
                    setFieldValue("endDate", "");
                  }
                }}
                onBlur={() => setFieldTouched("startDate", true)}
                value={
                  values.startDate
                    ? [
                        moment(values.startDate, "YYYY-MM-DD"),
                        moment(values.endDate, "YYYY-MM-DD"),
                      ]
                    : ""
                }
                // touched={touched.startDate}
                // error={errors.startDate || null}
              />
            </Col>
            <div className="mt-4  d-flex w-100 justify-content-end">
              <Space>
                <CsaButton
                  label="Cancel"
                  textColor="#007237"
                  onClick={() => handleClear()}
                  btnColor="#FFCB03"
                />
                <CsaButton
                  label="Continue"
                  textColor="#FFF"
                  onClick={() => handleSubmit()}
                  btnColor="#007237"
                  disabled={checkValidity()}
                />
              </Space>
            </div>
          </Row>
        )}
      </div>
    </>
  );
}

export default CompetitionInformation;
